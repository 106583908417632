import { Overlay, ScrollStrategy } from '@angular/cdk/overlay'
import { InjectionToken } from '@angular/core'
import { _BuiChipInputComponent } from './bui-chip-input.component'

export const BUI_SELECT_SCROLL_STRATEGY = new InjectionToken<
  () => ScrollStrategy
>('bui-chip-input-scroll-strategy')

export function BUI_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition()
}

export const BUI_SELECT_SCROLL_STRATEGY_PROVIDER = {
  provide: BUI_SELECT_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: BUI_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY,
}

export const BUI_PARENT_CHIP_INPUT_OPTION_COMPONENT =
  new InjectionToken<_BuiChipInputComponent>(
    'BUI_PARENT_CHIP_INPUT_OPTION_COMPONENT'
  )
