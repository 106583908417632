import {
  Component,
  AfterViewChecked,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ElementRef,
  ChangeDetectionStrategy,
  HostBinding,
  TemplateRef,
} from '@angular/core'
import { Subject } from 'rxjs'
import {
  BuiChipColor,
  BuiChipSize,
  BuiChipVariant,
} from '../bui-chip/bui-chip.component'

export class BuiChipInputChipRemove {
  constructor(
    public source: _BuiChipInputChipComponent,
    public isUserInput = false
  ) {}
}

@Component({
  selector: 'bui-chip-input-chip',
  templateUrl: 'bui-chip-input-chip.component.html',
  styleUrls: ['bui-chip-input-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiChipInputChipComponent implements AfterViewChecked, OnDestroy {
  @Input() value: any

  @Input() chipTemplate: TemplateRef<any>

  @Input() variant: BuiChipVariant = 'pill'

  @Input() color: BuiChipColor

  @Input() size: BuiChipSize = 'regular'

  private _mostRecentViewValue = ''
  public readonly _stateChanges = new Subject<void>()

  public get isSelected() {
    return this._isSelected
  }
  private _isSelected = false

  public get isActive() {
    return this._isActive
  }
  private _isActive = false

  @Input()
  public get disabled() {
    return this._disabled
  }
  public set disabled(disabled: boolean) {
    this._disabled = disabled
  }
  private _disabled = false

  public get viewValue() {
    return (this._getHostElement().textContent || '').trim()
  }

  @Output() readonly remove = new EventEmitter<BuiChipInputChipRemove>()

  @HostBinding('class.bui-chip-input-chip') private class = true
  @HostBinding('class.bui-chip-input-option--disabled')
  get disabledClass(): boolean {
    return this.disabled
  }

  constructor(
    private _element: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    if (this._isSelected) {
      const viewValue = this.viewValue

      if (viewValue !== this._mostRecentViewValue) {
        this._mostRecentViewValue = viewValue
        this._stateChanges.next()
      }
    }
  }

  ngOnDestroy(): void {
    this._stateChanges.complete()
  }

  // Removes the chip
  public onRemove(): void {
    if (!this._disabled) {
      this.remove.emit(new BuiChipInputChipRemove(this, true))
    }
  }

  // Gets the label to be used when determining
  // whether the option should be focused
  public getLabel(): string {
    return this.viewValue
  }

  // Gets the host DOM element
  private _getHostElement() {
    return this._element.nativeElement
  }
}
