import {
  Component,
  AfterViewChecked,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ElementRef,
  ChangeDetectionStrategy,
  HostBinding,
  HostListener,
  TemplateRef,
} from '@angular/core'
import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y'
import { Subject } from 'rxjs'
import { ENTER, SPACE, hasModifierKey } from '@angular/cdk/keycodes'

export class BuiChipInputOptionChange {
  constructor(
    public source: _BuiChipInputOptionComponent,
    public isUserInput = false
  ) {}
}

@Component({
  selector: 'bui-chip-input-option',
  templateUrl: 'bui-chip-input-option.component.html',
  styleUrls: ['bui-chip-input-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _BuiChipInputOptionComponent
  implements FocusableOption, AfterViewChecked, OnDestroy
{
  @Input() value: any

  @Input() optionTemplate: TemplateRef<any>

  private _mostRecentViewValue = ''
  public readonly _stateChanges = new Subject<void>()

  public get isSelected() {
    return this._isSelected
  }
  private _isSelected = false

  public get isActive() {
    return this._isActive
  }
  private _isActive = false

  @Input()
  public get disabled() {
    return this._disabled
  }
  public set disabled(disabled: boolean) {
    this._disabled = disabled
  }
  private _disabled = false

  public get viewValue() {
    return (this._getHostElement().textContent || '').trim()
  }

  @Output() readonly selectionChange =
    new EventEmitter<BuiChipInputOptionChange>()
  @Output() readonly optionHover = new EventEmitter<BuiChipInputOptionChange>()

  @HostBinding('class.bui-chip-input-option') private class = true
  @HostBinding('class.bui-chip-input-option--selected')
  get selectedClass(): boolean {
    return this.isSelected
  }
  @HostBinding('class.bui-chip-input-option--active')
  get activeClass(): boolean {
    return this.isActive
  }
  @HostBinding('class.bui-chip-input-option--disabled')
  get disabledClass(): boolean {
    return this.disabled
  }
  @HostBinding('attr.tabIndex') get tabIndex() {
    return this._getTabIndex()
  }
  @HostBinding('role') get role() {
    return 'option'
  }

  @HostListener('click') onClick(): void {
    this._selectViaInteraction()
  }
  @HostListener('mousemove') onMousemove(): void {
    this._emitOptionHoverEvent()
  }
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent): void {
    this._handleKeydown(event)
  }

  constructor(
    private _element: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    if (this._isSelected) {
      const viewValue = this.viewValue

      if (viewValue !== this._mostRecentViewValue) {
        this._mostRecentViewValue = viewValue
        this._stateChanges.next()
      }
    }
  }

  ngOnDestroy(): void {
    this._stateChanges.complete()
  }

  // Selects the options while indicating the selection
  // came for the user. Used to determine if the select's
  // view -> model callback should be invoked
  public _selectViaInteraction(): void {
    if (!this.disabled) {
      this._isSelected = true
      this._changeDetectorRef.markForCheck()
      this._emitSelectionChangeEvent(true)
    }
  }

  public _handleKeydown(event: KeyboardEvent) {
    if (
      (event.keyCode === ENTER || event.keyCode === SPACE) &&
      !hasModifierKey(event)
    ) {
      this._selectViaInteraction()
      event.preventDefault()
    }
  }

  // Selects the option
  public select(): void {
    if (!this._isSelected) {
      this._isSelected = true
      this._changeDetectorRef.markForCheck()
      this._emitSelectionChangeEvent()
    }
  }

  // Deselects the option
  public deselect(): void {
    if (this._isSelected) {
      this._isSelected = false
      this._changeDetectorRef.markForCheck()
      this._emitSelectionChangeEvent()
    }
  }

  // Sets focus onto this option
  public focus(_origin?: FocusOrigin, options?: FocusOptions): void {
    const element = this._getHostElement()
    if (typeof element.focus === 'function') {
      element.focus(options)
    }
  }

  // Sets display styles on the option to make it
  // appear active. This is used by the ActiveDescendantKeyManager
  // so key events will display the proper options as active
  // on arrow key events
  public setActiveStyles(): void {
    if (!this._isActive) {
      this._isActive = true
      this._changeDetectorRef.markForCheck()
    }
  }

  // Removes display styles on the option that made it
  // appear active. This is used by the ActiveDescendantKeyManager
  // so key events will display the proper options as active
  // on arrow key events
  public setInactiveStyles(): void {
    if (this._isActive) {
      this._isActive = false
      this._changeDetectorRef.markForCheck()
    }
  }

  // Returns the correct tabindex for the option depending
  // on disabled state
  public _getTabIndex(): string {
    return this.disabled ? '-1' : '0'
  }

  // Gets the label to be used when determining
  // whether the option should be focused
  public getLabel(): string {
    return this.viewValue
  }

  private _emitSelectionChangeEvent(isUserInput = false): void {
    this.selectionChange.emit(new BuiChipInputOptionChange(this, isUserInput))
  }

  private _emitOptionHoverEvent(): void {
    this.optionHover.emit(new BuiChipInputOptionChange(this, true))
  }

  // Gets the host DOM element
  private _getHostElement() {
    return this._element.nativeElement
  }
}
