<bui-chip
  class="bui-chip-input-chip"
  removable
  (remove)="onRemove()"
  [variant]="variant"
  [color]="color"
  [size]="size"
>
  <ng-container *ngTemplateOutlet="chipTemplate; context: { option: value }">
  </ng-container>
</bui-chip>
