import { NgModule } from '@angular/core'
import { _BuiChipInputComponent } from './bui-chip-input.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { _BuiChipInputOptionComponent } from './bui-chip-input-option.component'
import { BUI_SELECT_SCROLL_STRATEGY_PROVIDER } from './bui-chip-input.tokens'
import { BuiInputModule } from '../bui-input'
import { BuiChipModule } from '../bui-chip/bui-chip.module'
import { _BuiChipInputChipComponent } from './bui-chip-input-chip.component'

@NgModule({
  declarations: [
    _BuiChipInputComponent,
    _BuiChipInputOptionComponent,
    _BuiChipInputChipComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    BuiInputModule,
    BuiChipModule,
  ],
  providers: [BUI_SELECT_SCROLL_STRATEGY_PROVIDER],
  exports: [_BuiChipInputComponent, _BuiChipInputOptionComponent],
})
export class BuiChipInputModule {}
