import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  NgZone,
  Self,
  Optional,
  ChangeDetectionStrategy,
  Attribute,
  Inject,
  HostListener,
  HostBinding,
  booleanAttribute,
  TemplateRef,
  ViewChildren,
  AfterViewInit,
  SimpleChanges,
  type OnChanges,
} from '@angular/core'
import {
  Observable,
  defer,
  merge,
  Subject,
  OperatorFunction,
  BehaviorSubject,
  Subscription,
  of,
  EMPTY,
} from 'rxjs'
import { NgControl, ControlValueAccessor, FormControl } from '@angular/forms'

import { buiChipInputAnimations } from './bui-chip-input.animations'
import {
  _BuiChipInputOptionComponent,
  BuiChipInputOptionChange,
} from './bui-chip-input-option.component'
import { SelectionModel } from '@angular/cdk/collections'
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y'
import {
  CdkConnectedOverlay,
  ScrollStrategy,
  ViewportRuler,
  ConnectedPosition,
} from '@angular/cdk/overlay'
import {
  startWith,
  switchMap,
  take,
  takeUntil,
  distinctUntilChanged,
  tap,
  map,
  catchError,
} from 'rxjs/operators'
import {
  DOWN_ARROW,
  UP_ARROW,
  ENTER,
  SPACE,
  hasModifierKey,
  HOME,
  END,
} from '@angular/cdk/keycodes'
import {
  BUI_PARENT_CHIP_INPUT_OPTION_COMPONENT,
  BUI_SELECT_SCROLL_STRATEGY,
} from './bui-chip-input.tokens'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { BuiPanelPosition, getPanelPositions } from '../bui-panel-position'
import { injectDestroy } from '../util'
import {
  BuiChipColor,
  BuiChipSize,
  BuiChipVariant,
} from '../bui-chip/bui-chip.component'
import { isEqual } from 'lodash'

let nextUniqueId = 0
const OPTION_HEIGHT = 40
const PANEL_HEIGHT = 380

const BASE_CSS_CLASS = 'bui-chip-input'

@Component({
  selector: 'bui-chip-input',
  templateUrl: 'bui-chip-input.component.html',
  styleUrls: ['bui-chip-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    buiChipInputAnimations.transformPanel,
    buiChipInputAnimations.transformPanelWrap,
  ],
  providers: [
    {
      provide: BUI_PARENT_CHIP_INPUT_OPTION_COMPONENT,
      useExisting: _BuiChipInputComponent,
    },
  ],
})
export class _BuiChipInputComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges
{
  private readonly destroy$ = injectDestroy()
  private _scrollStrategyFactory: () => ScrollStrategy

  @HostBinding('attr.tabIndex') get getTabIndex() {
    return this.tabIndex
  }

  @HostBinding('attr.id') get getId(): string {
    return this.id
  }

  @HostBinding('class') get classes(): string[] {
    const classes = [BASE_CSS_CLASS]

    classes.push(`${BASE_CSS_CLASS}--${this.size}`)

    if (this.isPanelOpen) {
      classes.push(`${BASE_CSS_CLASS}--active`)
    }
    if (this.disabled) {
      classes.push(`${BASE_CSS_CLASS}--disabled`)
    }
    if (this.required) {
      classes.push(`${BASE_CSS_CLASS}--required`)
    }
    if (this.empty) {
      classes.push(`${BASE_CSS_CLASS}--empty`)
    }

    return classes
  }

  @Input() optionTemplate: TemplateRef<any>

  @Input() chipTemplate: TemplateRef<any>

  @Input() variant: BuiChipVariant = 'pill'

  @Input() color: BuiChipColor

  @Input() size: BuiChipSize = 'regular'

  @Input({ transform: booleanAttribute }) allowCreate = false

  @Input() createNewText = 'Create new'

  @Input() idProp: string

  @Input() search: OperatorFunction<string, readonly any[]>

  @Input() resolve: OperatorFunction<string[], readonly any[]>

  @Input() create: OperatorFunction<string, any>

  @Input()
  get id(): string {
    return this._id
  }
  set id(value: string) {
    this._id = value || this._uid
  }
  private _id: string

  private _uid = `bui-chip-input-${nextUniqueId++}`

  public get isPanelOpen(): boolean {
    return this._isPanelOpen
  }
  private _isPanelOpen = false

  @Input() public staticLabel: string

  // Whether filling out the select
  // is required in the form
  @Input()
  public get required(): boolean {
    return this._required
  }
  public set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value)
    this._changeDetectorRef.markForCheck()
  }
  private _required = false

  // The placeholder displayed in the trigger
  // of the select
  @Input()
  public get placeholder(): string {
    return this._placeholder
  }
  public set placeholder(value: string) {
    this._placeholder = value
    this._changeDetectorRef.markForCheck()
  }
  private _placeholder: string

  // compare with function that defaults
  // to strict equality of comparison objects / primitives
  @Input()
  public get compareWith() {
    return this._compareWith
  }
  public set compareWith(fn: (o1: any, o2: any) => boolean) {
    this._compareWith = fn
    if (this._selectionModel) {
      this._initializeSelection()
    }
  }

  @Input({ transform: booleanAttribute }) numericalTriggerValue = false
  @Input({ transform: booleanAttribute }) disabled = false
  @Input({ transform: booleanAttribute }) allowEmpty = false

  // The last measured value for the trigger's
  // client bounding rect
  public _triggerRect: ClientRect

  // Typeahead debounce
  @Input()
  get typeaheadDebounceInterval(): number {
    return this._typeaheadDebounceInterval
  }
  set typeaheadDebounceInterval(value: number) {
    this._typeaheadDebounceInterval = Number(value) || 0
  }
  private _typeaheadDebounceInterval: number

  @Input()
  public get tabIndex(): number {
    return this.disabled ? -1 : this._tabIndex
  }
  public set tabIndex(value: number) {
    this._tabIndex = value != null ? value : 0
  }
  private _tabIndex: number

  public get focused(): boolean {
    return this._focused || this._isPanelOpen
  }
  private _focused = false

  @Input() public panelPosition: BuiPanelPosition = 'start'

  // The currently selected option
  private get selected(): _BuiChipInputOptionComponent {
    return this._selectionModel.selected[0]
  }

  // The value displayed in the trigger
  public get triggerValue(): string {
    if (this.staticLabel) {
      return this.staticLabel
    }
    if (this.empty) {
      return ''
    }
    return this._selectionModel.selected[0].viewValue
  }

  public get empty(): boolean {
    return !this._selectionModel || this._selectionModel.isEmpty()
  }

  readonly CREATE_NEW_VALUE = 'CREATE_NEW_VALUE'

  readonly selectedItems$ = new BehaviorSubject<any[]>([])

  // Awesome interface for dealing with selection logic
  private _selectionModel: SelectionModel<_BuiChipInputOptionComponent>

  private searchSubscription: Subscription | undefined

  // All of the defined select options
  @ViewChildren(_BuiChipInputOptionComponent)
  options: QueryList<_BuiChipInputOptionComponent>

  // Trigger that opens the select
  @ViewChild('trigger') trigger: ElementRef

  // Panel containing the select options
  @ViewChild('panel') panel: ElementRef
  @ViewChild(CdkConnectedOverlay) overlayDir: CdkConnectedOverlay

  searchFC = new FormControl<string>('')

  options$ = new BehaviorSubject<readonly any[]>([])

  showCreateNew$ = new BehaviorSubject(false)

  public readonly optionSelectionChanges: Observable<BuiChipInputOptionChange> =
    defer(() => {
      const options = this.options
      if (options) {
        return options.changes.pipe(
          startWith(options),
          switchMap(() =>
            merge(...options.map((option) => option.selectionChange))
          )
        )
      }
      return this._ngZone.onStable.asObservable().pipe(
        take(1),
        switchMap(() => this.optionSelectionChanges)
      )
    }) as Observable<BuiChipInputOptionChange>

  public readonly optionHoverChanges: Observable<BuiChipInputOptionChange> =
    defer(() => {
      const options = this.options
      if (options) {
        return options.changes.pipe(
          startWith(options),
          switchMap(() => merge(...options.map((option) => option.optionHover)))
        )
      }
      return this._ngZone.onStable.asObservable().pipe(
        take(1),
        switchMap(() => this.optionHoverChanges)
      )
    }) as Observable<BuiChipInputOptionChange>

  // View -> model callback called when value changes
  private _onChange: (_: any) => void

  // View -> model callback called when select has been touched
  private _onTouched: () => void

  public _panelDoneAnimatingStream = new Subject<string>()

  private _keyManager: ActiveDescendantKeyManager<_BuiChipInputOptionComponent>

  private readonly triggerSearch$ = new Subject<void>()

  // Strategy used to handle scrolling while the
  // select panel is open
  public _scrollStrategy: ScrollStrategy

  @HostListener('focus') private _onFocus(): void {
    if (!this.disabled) {
      this._focused = true
      this._changeDetectorRef.markForCheck()
    }
  }

  @HostListener('blur') private _onBlur(): void {
    this._focused = false

    if (!this.disabled && !this.isPanelOpen) {
      this._onTouched && this._onTouched()
      this._changeDetectorRef.markForCheck()
    }
  }

  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent): void {
    this._handleKeydown(event)
  }

  constructor(
    private _elementRef: ElementRef<HTMLDivElement>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone,
    private _viewportRuler: ViewportRuler,
    @Optional() @Self() public ngControl: NgControl,
    @Attribute('tabIndex') tabIndex: string,
    @Inject(BUI_SELECT_SCROLL_STRATEGY) scrollStrategyFactory: any
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
    this.tabIndex = parseInt(tabIndex) || 0

    this._scrollStrategyFactory = scrollStrategyFactory
    this._scrollStrategy = this._scrollStrategyFactory()
  }

  ngOnInit(): void {
    this._selectionModel = new SelectionModel<_BuiChipInputOptionComponent>(
      false
    )

    this._changeDetectorRef.markForCheck()

    this._panelDoneAnimatingStream
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.isPanelOpen) {
          this.overlayDir.offsetX = 0
          this._changeDetectorRef.markForCheck()
        }
      })

    this._viewportRuler
      .change()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.isPanelOpen) {
          this._triggerRect = this.trigger.nativeElement.getBoundingClientRect()
          this._changeDetectorRef.markForCheck()
        }
      })
  }

  ngAfterViewInit(): void {
    this._initKeyManager()
    this.options.changes
      .pipe(
        tap(() => {
          this._initKeyManager()
        }),
        takeUntil(this.destroy$)
      )
      .subscribe()

    this._selectionModel.changed
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        event.added.forEach((option) => option.select())
        event.removed.forEach((option) => option.deselect())
      })

    this.options.changes
      .pipe(startWith(null), takeUntil(this.destroy$))
      .subscribe(() => {
        this._resetOptions()
        this._initializeSelection()
      })

    this._changeDetectorRef.markForCheck()
  }

  ngOnChanges({ search }: SimpleChanges): void {
    if (search) {
      if (this.searchSubscription) this.searchSubscription.unsubscribe()
      this.searchSubscription = merge(
        this.searchFC.valueChanges,
        this.triggerSearch$
      )
        .pipe(
          map(() => this.searchFC.value),
          this.search,
          tap((options) => {
            this.showCreateNew$.next(
              this.searchFC.value.length && !options.length && this.allowCreate
            )

            this.open(true)
            this.options$.next(options)
          }),
          takeUntil(this.destroy$)
        )
        .subscribe()
    }
  }

  public getPositions(): ConnectedPosition[] {
    return getPanelPositions(this.panelPosition)
  }

  public _handleKeydown(event: KeyboardEvent): void {
    if (!this.disabled) {
      if (this.isPanelOpen) {
        this._handleOpenKeydown(event)
      } else {
        this._handleClosedKeydown(event)
      }
    }
  }

  private _handleOpenKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode
    const keyManager = this._keyManager
    const isTyping = keyManager.isTyping()

    if (this._isHomeKeyPressed(keyCode) || this._isEndKeyPressed(keyCode)) {
      event.preventDefault()
      if (this._isHomeKeyPressed(keyCode)) {
        keyManager.setFirstItemActive()
      } else {
        keyManager.setLastItemActive()
      }
    } else if (
      !isTyping &&
      (this._isEnterKeyPressed(keyCode) || this._isSpaceKeyPressed(keyCode)) &&
      keyManager.activeItem &&
      !hasModifierKey(event)
    ) {
      event.preventDefault()
      keyManager.activeItem._selectViaInteraction()
    } else {
      keyManager.onKeydown(event)
    }
  }

  private _handleClosedKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode
    const isOpenKey = this._isOpenKey(keyCode)
    const keyManager = this._keyManager

    if (!keyManager.isTyping() && isOpenKey && !hasModifierKey(event)) {
      event.preventDefault()
      this.open()
    }
  }

  private _isArrowKeyPressed(keyCode: number): boolean {
    return keyCode === DOWN_ARROW || keyCode === UP_ARROW
  }

  private _isHomeKeyPressed(keyCode: number): boolean {
    return keyCode === HOME
  }

  private _isEndKeyPressed(keyCode: number): boolean {
    return keyCode === END
  }

  private _isSpaceKeyPressed(keyCode: number): boolean {
    return keyCode === SPACE
  }

  private _isEnterKeyPressed(keyCode: number): boolean {
    return keyCode === ENTER
  }

  private _isOpenKey(keyCode: number): boolean {
    return this._isSpaceKeyPressed(keyCode) || this._isEnterKeyPressed(keyCode)
  }

  private getSelectedIds() {
    return this.selectedItems$.value.map((item) => item[this.idProp])
  }

  // Part of the ControlValueAccessor interface required to
  // integrate with Angular's core forms API
  // when the control gets new value, it needs to resolve it first to be able to display chips
  public writeValue(value: any[]): void {
    const selectedIds = this.getSelectedIds()
    if (isEqual(value, selectedIds)) return
    this.resolve(of(value))
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((items) => this.selectedItems$.next([...items])),
        catchError(() => {
          console.error('Failed to resolve ids')
          return EMPTY
        })
      )
      .subscribe()
  }

  // Saves a callback function to be invoked when the select's value
  // changes from user input. Part of the ControlValueAccessor interface
  // required to integrate with Angular's core forms API.
  public registerOnChange(fn: any): void {
    this._onChange = fn
  }

  // Saves a callback function to be invoked when the select is blurred
  // by the user. Part of the ControlValueAccessor interface required
  // to integrate with Angular's core forms API
  public registerOnTouched(fn: any): void {
    this._onTouched = fn
  }

  // Disables the select. Part of the ControlValueAccessor interface
  // required to integrate with Angular's core forms API.
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
    isDisabled
      ? this.searchFC.disable({ emitEvent: false })
      : this.searchFC.enable({ emitEvent: false })
    this._changeDetectorRef.markForCheck()
  }

  public toggle(): void {
    this.isPanelOpen ? this.close() : this.open()
  }

  public focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options)
  }

  public open(ignoreSearch?: boolean): void {
    if (this.disabled || this._isPanelOpen) {
      return
    }
    if (!this.searchFC.value && !this.allowEmpty) {
      return
    }
    if (!ignoreSearch) {
      this.triggerSearch$.next()
    }
    this._triggerRect = this.trigger.nativeElement.getBoundingClientRect()
    this._isPanelOpen = true
    this._keyManager.withHorizontalOrientation(null)
    this._highlightCorrectOption()
    this._changeDetectorRef.markForCheck()
  }

  public close(): void {
    if (this._isPanelOpen) {
      this._isPanelOpen = false
      this._keyManager.withHorizontalOrientation('rtl')
      this._changeDetectorRef.markForCheck()
      this._onTouched && this._onTouched()
    }
  }

  // Callback to be invoked when the overlay panel
  // has been attached
  public _onAttached(): void {
    this.overlayDir.positionChange.pipe(take(1)).subscribe(() => {
      this._scrollActiveOptionIntoView()
      this._changeDetectorRef.detectChanges()
    })
  }

  public trackByIndex(index: number) {
    return index
  }

  public onChipRemove(item: any) {
    this.selectedItems$.next(
      this.selectedItems$.value.filter((v) => v !== item)
    )
  }

  private _setSelectionByValue(value: any): void {
    this._selectionModel.clear()
    const correspondingOption = this._selectValue(value)
    if (correspondingOption) {
      this._keyManager.setActiveItem(correspondingOption)
    } else if (!this.isPanelOpen) {
      this._keyManager.setActiveItem(-1)
    }

    this._changeDetectorRef.markForCheck()
  }

  private _selectValue(value: any): _BuiChipInputOptionComponent | undefined {
    const correspondingOption = this.options.find(
      (option: _BuiChipInputOptionComponent) =>
        option.value != null && this._compareWith(option.value, value)
    )
    if (correspondingOption) {
      this._selectionModel.select(correspondingOption)
    }
    return correspondingOption
  }

  private _onSelect(
    option: _BuiChipInputOptionComponent,
    isUserInput: boolean
  ): void {
    const wasSelected = this._selectionModel.isSelected(option)
    if (option.value == null) {
      option.deselect()
      this._selectionModel.clear()
      this._propagateChanges(option.value)
    } else {
      if (wasSelected !== option.isSelected) {
        option.isSelected
          ? this._selectionModel.select(option)
          : this._selectionModel.deselect(option)
      }
      if (isUserInput) {
        this._keyManager.setActiveItem(option)
      }
    }

    if (wasSelected !== this._selectionModel.isSelected(option)) {
      this._propagateChanges()
    }
  }

  private _propagateChanges(fallbackValue?: any): void {
    let valueToEmit: any = null
    valueToEmit = this.selected
      ? (this.selected as _BuiChipInputOptionComponent).value
      : fallbackValue
    if (valueToEmit === this.CREATE_NEW_VALUE) {
      this.create(of(this.searchFC.value))
        .pipe(
          tap((newValue) => {
            if (!newValue) return
            this.selectedItems$.next([...this.selectedItems$.value, newValue])
            this._onChange?.(this.getSelectedIds())
            this.searchFC.setValue('', { emitEvent: false })
          }),
          take(1),
          takeUntil(this.destroy$)
        )
        .subscribe()
    } else {
      this.selectedItems$.next([...this.selectedItems$.value, valueToEmit])
      this._onChange?.(this.getSelectedIds())
    }
    this._changeDetectorRef.markForCheck()
  }

  private _initializeSelection(): void {
    Promise.resolve(null).then(() => {
      this._setSelectionByValue(this.ngControl?.value)
      this._changeDetectorRef.markForCheck()
    })
  }

  private _resetOptions(): void {
    const changedOrDestroyed = merge(this.options.changes, this.destroy$)

    this.optionSelectionChanges
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe((event) => {
        this._onSelect(event.source, event.isUserInput)
        if (event.isUserInput && this._isPanelOpen) {
          this.close()
          this.focus()
        }
      })

    this.optionHoverChanges
      .pipe(distinctUntilChanged(), takeUntil(changedOrDestroyed))
      .subscribe((event) => this._keyManager.setActiveItem(event.source))

    merge(...this.options.map((option) => option._stateChanges))
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe(() => {
        this._changeDetectorRef.markForCheck()
      })
  }

  private _initKeyManager() {
    this._keyManager =
      new ActiveDescendantKeyManager<_BuiChipInputOptionComponent>(this.options)
        .withTypeAhead(this._typeaheadDebounceInterval)
        .withVerticalOrientation()
        .withHorizontalOrientation('ltr')

    this._keyManager.tabOut.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.isPanelOpen) {
        if (this._keyManager.activeItem) {
          this._keyManager.activeItem._selectViaInteraction()
        }
        this.focus()
        this.close()
      }
    })

    this._keyManager.change.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.isPanelOpen && this.panel) {
        this._scrollActiveOptionIntoView()
      } else if (!this.isPanelOpen && this._keyManager.activeItem) {
        this._keyManager.activeItem._selectViaInteraction()
      }
    })
  }

  private _highlightCorrectOption(): void {
    if (this._keyManager) {
      if (this.empty) {
        this._keyManager.setFirstItemActive()
      } else {
        this._keyManager.setActiveItem(this._selectionModel.selected[0])
      }
    }
  }

  private _scrollActiveOptionIntoView() {
    const activeOptionIndex = this._keyManager.activeItemIndex || 0
    this.panel.nativeElement.scrollTop = this._getOptionScrollPosition(
      activeOptionIndex,
      OPTION_HEIGHT,
      this.panel.nativeElement.scrollTop,
      PANEL_HEIGHT
    )
  }

  private _getOptionScrollPosition(
    optionIndex: number,
    optionHeight: number,
    currentScrollPosition: number,
    panelHeight: number
  ): number {
    const optionOffset = optionIndex * optionHeight
    if (optionOffset < currentScrollPosition) {
      return optionOffset
    }

    if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
      return Math.max(0, optionOffset - panelHeight + optionHeight)
    }

    return currentScrollPosition
  }

  private _compareWith(o1: any, o2: any): boolean {
    return o1 === o2
  }
}
