<div
  class="bui-chip-input-trigger"
  #trigger
  #origin="cdkOverlayOrigin"
  cdkOverlayOrigin
  (click)="toggle()"
>
  <bui-chip-input-chip
    *ngFor="let item of selectedItems$ | async"
    [value]="item"
    [chipTemplate]="chipTemplate"
    [variant]="variant"
    [color]="color"
    [size]="size"
    (remove)="onChipRemove(item)"
  ></bui-chip-input-chip>

  <div class="bui-chip-input-input-wrapper">
    <input
      class="bui-chip-input-input"
      [placeholder]="placeholder"
      [formControl]="searchFC"
    />
  </div>

  <ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayLockPosition
    cdkConnectedOverlayHasBackdrop
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
    [cdkConnectedOverlayOpen]="isPanelOpen"
    [cdkConnectedOverlayPositions]="getPositions()"
    [cdkConnectedOverlayMinWidth]="_triggerRect?.width"
    (backdropClick)="close()"
    (attach)="_onAttached()"
    (detach)="close()"
  >
    <div class="bui-chip-input-panel-wrap" [@transformPanelWrap]>
      <div
        #panel
        [attr.id]="id + 'panel'"
        role="listbox"
        tabindex="-1"
        class="bui-chip-input-panel"
        [@transformPanel]="'showing'"
        (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
        (keydown)="_handleKeydown($event)"
      >
        <ng-container
          *ngFor="let option of options$ | async; trackBy: trackByIndex"
        >
          <bui-chip-input-option
            [optionTemplate]="optionTemplate"
            [value]="option"
          ></bui-chip-input-option>
        </ng-container>
        @if (showCreateNew$ | async) {
          <bui-chip-input-option
            [optionTemplate]="createNewTemplate"
            [value]="CREATE_NEW_VALUE"
          ></bui-chip-input-option>
        }
      </div>
    </div>
  </ng-template>
</div>

<ng-template #createNewTemplate>
  <span>{{ searchFC.value }} not found.</span>
  <span class="bui-chip-input-create-new">{{ createNewText }}</span>
</ng-template>
